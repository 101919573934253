/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.11 cloud.glb
*/
import { useGLTF } from '@react-three/drei'
import * as THREE from "three"

export default function CloudModel(props) {
  const { nodes } = useGLTF('/cloud.glb')
  return (
      <mesh 
        geometry={nodes.low_poly_cloud_v1.geometry}
        {...props}
      >
        <meshStandardMaterial 
          color={props.cloudColor} 
          transparent 
          opacity={0.7}
          metalness={0.5}
        />
      </mesh>
  )
}

useGLTF.preload('/cloud.glb')
