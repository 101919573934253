export default function About() {

    return(
        <div id="about">
            <div id="title-container">
                <h1 id="title">About Me</h1>
            </div>
            <br /> <br />
            <div id="about-container">
                <br />
                <p>I’m an engineering student passionate about building things and sharing them on the internet. I love experimenting and integrating hardware (circuits, logic gates), software (microcontrollers, web development) and design (PCB design, 3D printing) with aspects of mathematics and science in the form of projects that I can showcase. I’m currently interested in exploring and utilizing neuroplasticity in robotics, AI, machine learning and biotechnology.</p>
                <br /><br />
                <p>I am curious, resourceful and an adept problem-solver, and have acquired many of my current skills through self-directed exploration and learning.</p>
                <br /><br />
                <p>In my free time, I love playing the guitar, doing parkour, gaming, and recently picked up boxing. I also enjoy deep meaningful conversations, admiring and taking inspiration from different kinds of creative work, as well as delving into topics outside my field of study (currently it's geopolitics).</p>
                <br />
            </div>
        </div>
    )
}